import BaseCrudService from "./baseCrud.service";

export default class ReceiverParcelamentService extends BaseCrudService {

    constructor() {
        super('ReceiverParcelament');
    }

    saveReceiverParcelament(saveReceiverParcelament) {
      this.postRequest('SaveReceiverParcelament', saveReceiverParcelament);
    }

    listAll() {
      return this.getRequest('GetReceiverParcelaments');
    }
}